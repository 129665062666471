.container.blur {
    filter: blur(5px);
    /* Apply blur effect only when blur class is present */
}

.big-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    /* Adjust the width as needed */
    max-width: 1200px;
    /* Optionally, set a maximum width */
    height: 80%;
    /* Adjust the height as needed */
    /* max-height: 800px; */
    /* Optionally, set a maximum height */
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    padding: 50px;
    overflow: auto;
    /* Enable scrolling if content overflows */
    z-index: 10000;

}

.close-button {
    position: absolute;
    top: 4px;
    right: 4px;
    font-size: 22px;
}

.MuiTypography-root.MuiTypography-button.css-1fgg2c1-MuiTypography-root {
    font-size: 10px;
    /* Adjust the font size of the progress bar*/
}


.recharts-polar-radius-axis-tick-value {
    font-size: 25px;
}

.radiusAxis {
    font-size: 21px;
}

.recharts-text {
    font-size: 8px;
}

.recharts-polar-angle-axis-tick-value {
    font-size: 15px;
    word-break: break-all;
}

/* metakinei to chart*/
.recharts-layer.recharts-polar-angle-axis-ticks {
    transform: translateY(-80px);
}
/* metakinei to chart*/

.recharts-polar-grid {
    transform: translateY(-80px);
    /* Adjust the value as needed */
}

/* metakinei to chart*/
.recharts-layer .recharts-radar-polygon {
    transform: translateY(-80px);
}

.css-1l4w6pd{
    height: 400px;
}
